import React, { Component } from "react"
import logo from "../images/logo.png"

class Categories extends Component {
  state = {
  }

  setCollections = async props => {
    const { categories, dishesSelected } = props
    if (dishesSelected && categories) {
      for (let j = 0; j < categories.length; j++) {
        let counter = 0
        for (let i = 0; i < dishesSelected.length; i++) {
          if (dishesSelected[i].categoryId === categories[j].id) {
            counter += 1
          }
        }
        categories[j].countSelected = counter
      }
    }
    this.setState({ categories })
  }

  componentDidMount = () => {
    this.setCollections(this.props)
  }

  componentWillReceiveProps = props => {
    this.setCollections(props)
  }

  handleChangeCategory = category => {
    this.props.onChange(category)
  }

  handleResetSelections = () => {
    this.setCollections(this.props)
    this.props.onReset()
  }

  render() {
    const { categorySelected, categories } = this.props

    return (
      <div className="pad-categories">
        <div className="row m-0 p-0 p-3 category">
          <img
            src={logo}
            alt="logo"
            className="divCenterd"
            style={{ width: "93px", height: "115px", margin: "0 auto" }}
            onDoubleClick={this.handleResetSelections}
          />
        </div>

        {categories && categories.map(category => (
          <div className=" c-pointer " key={category.id}>
            <div className="row m-0 p0 ">
              <hr className="divider m-0 " />
            </div>
            <div
              className={
                "row m-0 py-4 px-4 category menuItem " +
                (category.id === categorySelected.id ? "categorySelected" : "")
              }
              onClick={() => this.handleChangeCategory(category)}
            >
              <div
                className="divCentered c"
                style={{ fontSize: "1em", letterSpacing: "2px" }}
              >
                {category.countSelected > 0 && (
                  <span className="pull-right bg-danger px-2 mr-2 categoryPill">
                    {category.countSelected}
                  </span>
                )}
                {category.attributes.name && category.attributes.name.toUpperCase()}
              </div>
            </div>
          </div>
        ))}
        <div className="row m-0 p-0  ">
          <hr className="divider m-0" />
        </div>
      </div>
    )
  }
}

export default Categories
