import axios from "axios"
import { toast } from "react-toastify"

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

axios.interceptors.response.use(null, error => {
  /* const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500*/

  let message = ""
  switch (error.response.status) {
    case 400:
      message = "Richiesta al server errata"
      break
    case 401:
      message = "Non sei autorizzato per compiere questa azione"
      break
    case 403:
      message = "Non sei autenticato a compiere questa azione"
      break
    case 404:
      message = "Elemento non trovato a sistema"
      break
    case 405:
      message = "Metodo non concesso"
      break
    case 422:
      message = "I dati inseriti non sono validi, prego controllare"
      break
    default:
      break
  }
  if (message !== "") {
    toast.error(message)
  }

  return Promise.reject(error)
})

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
}
