import React, { Component } from "react"
import Categories from "../components/categories"
import Modal from "react-bootstrap/Modal"
import { FaRegCircle, FaCheckCircle, FaChevronLeft } from "react-icons/fa"
import "bootstrap/dist/css/bootstrap.css"
import "../components/skeinetStyles.css"
import "../components/layout.css"
import { loadDishes, loadCategories } from "../utils/functions"

class Menu extends Component {
  state = {
    menu: [],
    open: false,
    productSelected: {},
    categorySelected: { id: 1 },
    catgoeries: [],
    dishesSelected: []
  }

  componentDidMount = async () => {
    const categories = await loadCategories()
    const categorySelected = categories[0]
    const menu = await loadDishes(categorySelected)

    this.setState({ menu, categories, categorySelected })
  }

  handleClickItem = item => {
    const { menu } = this.state
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].id === item.id) {
        this.setState({ productSelected: item, open: true })
      }
    }
    this.setState({ menu })
  }

  handleHide = () => {
    this.setState({ open: false })
  }

  handleShow = () => {
    this.setState({ open: true })
  }

  handleChangeCategory = async categorySelected => {
    const menu = await loadDishes(categorySelected)
    this.setState({ categorySelected, menu })
  }

  handleRememberFood = () => {
    const { productSelected, dishesSelected } = this.state
    let dish = {}
    dish.productId = productSelected.id
    dish.categoryId = productSelected.relationships.categories.data[0].id
    let flag = false
    for (let i = 0; i < dishesSelected.length; i++) {
      if (dishesSelected[i].productId === dish.productId) {
        dishesSelected.splice(i, 1)
        flag = true
        break;
      }
    }
    if (!flag) { dishesSelected.push(dish) }
    this.setState({ dishesSelected, open: false })
  }

  handleCloseModal = () => {
    this.setState({ open: false })
  }

  handleReset = () => {
    let dishesSelected = []
    this.setState({ dishesSelected })
  }

  getDishClass = (item) => {
    const { dishesSelected } = this.state
    let flag = false
    for (let i = 0; i < dishesSelected.length; i++) {
      if (dishesSelected[i].productId === item.id) {
        flag = true
        break;
      }
    }
    return flag ? "bg-selected col-4 py-2" : "col-4 px-2 py-2"
  }

  getPopupDishClass = (item) => {
    const { dishesSelected } = this.state
    let flag = false
    for (let i = 0; i < dishesSelected.length; i++) {
      if (dishesSelected[i].productId === item.id) {
        flag = true
        break;
      }
    }
    return flag ? "btn btn-danger bnt-lg w-100" : "btn btn-default bnt-lg w-100"
  }

  getPopupDishLabel = (item) => {
    const { dishesSelected } = this.state
    let flag = false
    for (let i = 0; i < dishesSelected.length; i++) {
      if (dishesSelected[i].productId === item.id) {
        flag = true
        break;
      }
    }
    return flag ? "Dimentica questo piatto" : "Ricorda questo piatto"
  }

  render() {
    const { productSelected, menu, categories, categorySelected, dishesSelected } = this.state
    return (
      <div className="row m-0 h-100">
        <Modal
          show={this.state.open}
          onHide={this.handleHide}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <span style={{ fontSize: "1.2em " }}>
                {categorySelected && categorySelected.attributes && categorySelected.attributes.name} : <strong>{productSelected.attributes && productSelected.attributes.title}</strong>
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-8">
                <img
                  className="w-100 plateBorder"
                  src={productSelected && productSelected.relationships && productSelected.relationships.media.data[0].attributes.url}
                  alt="thumb food"
                />
              </div>
              <div className="col-4">
                {productSelected.attributes && (
                  <div
                    style={{ fontSize: "1.3em ", lineHeight: "0.8em" }}
                    dangerouslySetInnerHTML={{
                      __html: productSelected.attributes.body,
                    }}
                  />
                )}
                <h2>{productSelected.attributes && productSelected.attributes.price} €</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-danger bnt-lg w-100"
                  onClick={this.handleCloseModal}
                >
                  <p className="m-0" style={{ fontSize: "1.4em" }}>
                    <FaChevronLeft /> Torna al menù
                  </p>
                </button>
              </div>
              <div className="col-6">
                <button
                  className={this.getPopupDishClass(productSelected)}
                  onClick={this.handleRememberFood}
                >
                  <p className="m-0" style={{ fontSize: "1.4em" }}>
                    {productSelected.selected === true ? (
                      <FaCheckCircle />
                    ) : (
                        <FaRegCircle />
                      )}{" "}
                    {this.getPopupDishLabel(productSelected)}

                  </p>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className="leftSideMenuArea">
          <Categories
            categorySelected={categorySelected}
            dishesSelected={dishesSelected}
            menu={menu}
            categories={categories}
            onChange={this.handleChangeCategory}
            onReset={this.handleReset}
          />
        </div>
        <div className="foodBackgroundBorder foodArea foodBackground">
          <div className="row  m-0">
            {menu && menu.map(
              item =>
                item.relationships.categories.data[0].id === categorySelected.id && (
                  <div
                    key={item.id}
                    className={this.getDishClass(item)}
                    onClick={() => this.handleClickItem(item)}
                  >
                    {item.relationships.media.data.length > 0 && (
                      <img
                        className="mb-0 plateBorder"
                        src={item.relationships.media.data[0].attributes.url}
                        alt="foot thumb "
                      />
                    )}

                    <div className="foodLabel">
                      <p className="m-0 " style={{ fontSize: "1em" }}>
                        {item.attributes.title}
                      </p>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Menu
