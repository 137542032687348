import http from "./httpService"
import { httpHeaders } from "../utils/functions"
import { toast } from "react-toastify"
import { apiUrl } from "../config.json"

export async function getItems(collectionName, filters) {
  const userEndpoint = apiUrl + collectionName
  let url = userEndpoint
  if (filters) {
    const { currentPage, searchQuery } = filters
    const { path, order } = filters.sortColumn
    let sort = path
    if (order === "desc") {
      sort = "-" + sort
    }
    url =
      userEndpoint +
      "?page=" +
      currentPage +
      "&sort=" +
      sort
    if (searchQuery) {
      url += "&query=" + searchQuery
    }
  }
  //url += "?locale=it"
  try {
    const { data } = await http.get(url, httpHeaders())
    console.log("url", url, "data", data)
    let dataToReturn = { ...data }
    if (filters) {
      dataToReturn.sortColumn = filters.sortColumn
    }
    return dataToReturn
  } catch (error) {
    toast.error(
      "Errore nel recuperare la lista [" +
      collectionName +
      "]. Ricaricare la pagina o contattare l'assistenza")
    return { data: [] }
  }
}

export async function getProducts(path, query) {
  let userEndpoint = apiUrl + path
  try {
    userEndpoint += "?filter[query]=" + query
    const { data } = await http.get(userEndpoint, httpHeaders())
    let dataToReturn = { ...data }
    return dataToReturn.data
  } catch (error) {
    toast.error(
      "Errore nel recuperare la lista [" +
      path +
      "]. Ricaricare la pagina o contattare l'assistenza")
    return { data: [] }
  }
}

export async function setItem(path, data) {
  const userEndpoint = apiUrl + path
  try {
    if (data.id > 0) {
      await http.patch(userEndpoint, data, httpHeaders())
    } else {
      await http.post(userEndpoint, data, httpHeaders())
    }
    return true
  } catch (ex) {
    toast.error(
      "Errore nel salvataggio dati.Riprovare o contattare l'assistenza")
    return false
  }
}

export async function postItem(path, data) {
  const userEndpoint = apiUrl + path
  try {
    await http.post(userEndpoint, data, httpHeaders())
    return true
  } catch (ex) {
    toast.error(
      "Errore invio dati al server.Riprovare o contattare l'assistenza")
    return false
  }
}

export async function getItem(path, id) {
  const userEndpoint = apiUrl + path
  try {
    const { data } = await http.get(
      userEndpoint + "/" + id + "?locale=" + process.env.LOCALE,
      httpHeaders()
    )
    //console.log("getItem - data", data);
    return data
  } catch (error) {
    console.log("getItem error", error)
  }
}

export async function deleteItem(path, id) {
  const userEndpoint = apiUrl + path
  try {
    await http.delete(userEndpoint + "/" + id, httpHeaders())
    //  console.log("Data", data);
  } catch (error) {
    console.log("error", error)
  }
}
